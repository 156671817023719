import "./HeroPlayerElement.less";

interface Quote {
    stars?: number;
    quote: string;
    author: string;
}

export class HeroPlayerElement extends HTMLElement {
    static tag = "hero-player";
    private _randomizedQuotes: Quote[] = this.shuffle(HeroPlayerElement._quotes);

    private static _quotes: Quote[] = [
        {
            stars: 5,
            quote: "This is really fun. Captures that escape room feeling well.",
            author: "Christian Vandsø Andersen"
        },
        {
            stars: 5,
            quote: "My son and I played it yesterday<br> - it was fun to visit the old grocery store and the gamer and the parent both enjoyed the escape puzzles.",
            author: "Sussi Bianco",

        },
        {
            quote: "This is really, really good!<br>What other online escape rooms have this kind of intuitive interface and real escape room flow?",
            author: "Brian Mumps",

        },
        {
            stars: 5,
            quote: "Fabulous game! Excellent variety of puzzles, great graphics and plot.<br />Loved the fact you could turn things round in the inventory (took a while for that penny to drop!)<br />Would thoroughly recommend, can't wait for the next one!",
            author: "Sue",

        }
    ];

    connectedCallback() {
        window.requestIdleCallback(this.startQuoteRotation, { timeout: 1000 });
    }

    private _index = 0;
    private startQuoteRotation = () => {
        this._index = (this._index + 1) % this._randomizedQuotes.length;
    
        const blockquote = this.querySelector("blockquote");
        if (!blockquote) {
            setTimeout(this.startQuoteRotation, 8000); // Continue the loop
            return;
        }
    
        blockquote.toggleAttribute("hide", true);
    
        setTimeout(() => {
            requestAnimationFrame(() => {
                blockquote.innerHTML = this.quoteViewContent(this._randomizedQuotes[this._index]);
    
                requestAnimationFrame(() => {
                    blockquote.removeAttribute("hide");
                });
            });
        }, 3000);
    
        setTimeout(this.startQuoteRotation, 13000); // Continue the loop
    };
    
    public static delay(milliseconds: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    private async waitForTransitionEnd(element: HTMLElement) {
        return new Promise(resolve => {
            element.addEventListener("transitionend", resolve);
        });
    }

    private shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex > 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    private quoteViewContent = (quote: Quote) => `
        ${quote.stars ? `<span class="stars">${"★".repeat(quote.stars)}</span>` : ""}
        <p>${quote.quote}</p>
        <cite>~ ${quote.author}</cite>
    `;
}

customElements.define(HeroPlayerElement.tag, HeroPlayerElement);