import { LoaderButtonElement } from 'Element/LoaderButtonElement';
import "./GameCodeElement.less";
import { CodeBoxElement } from './CodeBoxElement';
import * as party from "Library/party-js";
import { VisitorStore } from 'Feature/Visitor';
import { LogStore } from 'Feature/Logging/LogStore';
import { GamesScrollerElement } from '../Games';

export class GameCodeElement extends HTMLElement {
    static tag = "game-code";
    private _loaderButton: LoaderButtonElement;
    private _email: HTMLInputElement;
    private _errorMessage: HTMLElement;
    private _form: HTMLFormElement;

    private campaignId = () => this.getAttribute("campaign-id");
    
    connectedCallback() {
        document.body.setAttribute("name", "puzzle");
        //this.classList.add("box");
        this.innerHTML = this.view();

        party.confetti(this, {
            count: 180
        });

        this._form = this.querySelector("form");
        this._form.addEventListener("submit", this.formSubmit);
        this._email = this.querySelector("input[type=email]");
        this._errorMessage = this.querySelector(".error-message");
        this._loaderButton = this.querySelector(LoaderButtonElement.tag);
        this._loaderButton.addEventListener("click", this.submitEmail);

        setTimeout(() => {
            this._email.focus();
        }, 200);

        this.parentElement.appendChild(new GamesScrollerElement());
    }

    private formSubmit = async (event: Event) => { 
        event.preventDefault();
        await this.submitEmail();
    }

    private disableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = true);
    }

    private enableAllInputs = () => { 
        this._form.querySelectorAll("input").forEach(input => input.disabled = false);
    }

    private submitEmail = async () => {
        LogStore.instance.logInformation("Entered email: " + this._email.value, {
            page: window.location.pathname,
            title: document.title,
            referrer: document.referrer,
            eventKind: "page-hit",
            email: this._email.value,
            campaignId: this.campaignId(),
            notification: true,
            visitorId: VisitorStore.instance.visitorId,
            visitorSessionId: VisitorStore.instance.visitorSessionId
        });


        this._loaderButton.toggleAttribute("busy", true);
        this.disableAllInputs();

        const response = await fetch("/gamecode", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this._email.value,
                campaignId: this.campaignId(),
                visitorId: VisitorStore.instance.visitorId,
                visitorSessionId: VisitorStore.instance.visitorSessionId
            })
        });

        if (!response.ok) {
            this._errorMessage.innerText = "Something went wrong. Please try again.";
            this._loaderButton.toggleAttribute("busy", false);
            return;
        }


        const model = await response.json() as GameCodeResponse;

        this.innerHTML = this.successView(model);

        this._loaderButton.toggleAttribute("busy", false);
        this.enableAllInputs();
    }

    private view = () => {
        return `
            <div>
                <form>
                <h1>🎉&nbsp;<span>Congratulations!</span></h1>
                <p>You solved the puzzle!</p>
                <p><b>Would you like access to more puzzles?</p>
                    

                <div class="box signup">
                    <p>Enter your email address below to unlock our free online escape game and we will send you a link to play.</p>
                    <input type="email" placeholder="someone@somewhere.com"></input>
                    <p class="error-message"></p>
                    <${LoaderButtonElement.tag}><span>✔︎</span> Sign me up</${LoaderButtonElement.tag}>
                    <p>We promise to keep your email a secret and not bother you very often.</p>
                </div>

                
                
                
                </form>
            </div>
            
        `;
    }

    private successView = (model: GameCodeResponse) => {
        return `
            <h1>Thanks!</h1>
            <p>We have sent you an email with a code and link to the game.</p>

            <p>Your game code is</p>
            
            <${CodeBoxElement.tag}>${model.code}</${CodeBoxElement.tag}>

            <p>(One code is enough for the whole team. Just share it with your team members)</p>

            <p>Please note that this game is exclusively optimized for desktop platforms.</p>

            <p>Click <a href="${model.gameUrl}">here</a> to play now.</a></p>

           
            <p>Enjoy!</p>
        `;
    }
}

interface GameCodeResponse {
    code: string;
    gameName: string;
    gameUrl: string;
    codeUrl: string;
}

customElements.define(GameCodeElement.tag, GameCodeElement);