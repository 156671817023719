import "./GamesScrollerElement.less";

export class GamesScrollerElement extends HTMLElement {
    static readonly tag = "games-scroller";

    private static readonly _images = [
        require("./JPHansen/Images/Screenshots/screenshot-clock-small.jpg"),  
        require("./JPHansen/Images/Screenshots/screenshot-coffee-small.jpg"),
        require("./JPHansen/Images/Screenshots/screenshot-instructions-small.jpg"),
        require("./JPHansen/Images/Screenshots/screenshot-rack-small.jpg"),
        require("./JPHansen/Images/Screenshots/screenshot-inventory-small.jpg"), 
        require("./PiratePursuit/Images/Screenshots/cell-small.jpg"),
        require("./PiratePursuit/Images/Screenshots/item-small.jpg"),
        require("./PiratePursuit/Images/Screenshots/watertank-small.jpg"),
        require("./MissionPossible/Images/Screenshots/control-panel-small.jpg"),
        require("./MissionPossible/Images/Screenshots/snow-globe-small.jpg"),
        require("./MissionPossible/Images/Screenshots/stained-glass-small.jpg"),
        require("./MissionPossible/Images/Screenshots/street-small.jpg"),
        require("./MissionPossible/Images/Screenshots/train-small.jpg")
    ];

    private _container!: HTMLDivElement;
    private _scrolling = true;

    connectedCallback() {
        this.innerHTML = this.view();
        this._container = this.querySelector(".games-scroller") as HTMLDivElement;

        requestAnimationFrame(this.fillupWithImages);

        // this.fillupWithImages();

        requestIdleCallback(this.initScroll);
        // this.initScroll(); 
    }

    private view = () => { 
        return `
            <a href="/#rooms">
                <div class="games-scroller">
                   
                </div>
                <div class=text>Also check out our other online escape rooms</div>
            </a>
        `;
    };

    // private initScroll() {
    //     const step = () => {
    //         if (this._scrolling) {
    //             const scrollAmount = 1; // Adjust for speed
    //             this._container.scrollLeft += scrollAmount;
    
    //             // Add images to keep the scroller filled
    //             if (this._container.scrollLeft >= this._container.scrollWidth - this._container.clientWidth) {
    //                 this.appendRandomImage();
    //             }
    
    //             // Remove images that are out of view
    //             //this.removeOutOfViewImages();
    //         }
    //         requestAnimationFrame(step);
    //     };
    //     step();
    // }

    private initScroll = () => {
        let currentOffset = 0; // Tracks the scroll offset
    
        const step = () => {
           
            const scrollAmount = 0.5; // Fractional value for smooth scrolling
            currentOffset -= scrollAmount;

            // Apply transform to move the scroller
            this._container.style.transform = `translateX(${currentOffset}px)`;

            // Add images to keep the scroller filled
            const lastImage = this._container.lastElementChild as HTMLImageElement;
            const containerWidth = Math.min(window.innerWidth, this._container.getBoundingClientRect().width) + 400;

            if (lastImage && lastImage.getBoundingClientRect().right < containerWidth) {
                this.appendRandomImage();
            }
        
            requestAnimationFrame(step);
        };
    
        step();
    }

    private fillupWithImages = () => { 
        const containerWidth =  Math.max(window.innerWidth, this._container.getBoundingClientRect().width) + 400;
        let currentWidth = 0;

        const count = Math.ceil(containerWidth / 220);
        for (let i = 0; i < count; i++) {
            this.appendRandomImage();
        }
    }
         
    
    private appendRandomImage() {
        const randomImage = GamesScrollerElement._images[Math.floor(Math.random() * GamesScrollerElement._images.length)];
        const imgElement = document.createElement("img");
        imgElement.src = randomImage;

        this._container.appendChild(imgElement);
    }
}

customElements.define(GamesScrollerElement.tag, GamesScrollerElement);
