import "./SocialLinksElement.less"

class SocialLinksElement extends HTMLElement {
    static readonly tag = 'social-links';  

    connectedCallback() {
        this.innerHTML = this.view();
    }
    
    private view = () => `
     <header>Contact us</header>
        <p>We are happy to hear from you if you have feedback, tips or ideas.</p>

        <img src="/dist/dear-zebra-escapes-render.png" loading="lazy" />

        <div>
            <a href="https://bsky.app/profile/zebraescapes.com" title="Bluesky">
                ${require("!!raw-loader!./Images/Socials/bluesky.svg").default}
            </a>
            <a href="mailto:info@zebraescapes.com" title="Email">
                ${require("!!raw-loader!./Images/Socials/email.svg").default}
            </a>
            <a href="https://www.facebook.com/zebraescapes" title="Facebook">
                ${require("!!raw-loader!./Images/Socials/facebook.svg").default}
            </a>
            <a href="https://www.youtube.com/channel/UCl7Tg9cGy-EQbQfeCcQJFtQ" title="You Tube">
                ${require("!!raw-loader!./Images/Socials/youtube.svg").default}
            </a>
        </div>
    
    `;
}

customElements.define(SocialLinksElement.tag, SocialLinksElement);  
