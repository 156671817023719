import "./SpotTheDifferencesElement.less";

import "./FoundSpotElement.less";
import { SpotFinderElement } from "./SpotFinderElement";
import { SpotStore } from "./SpotStore";
import { UserPromptElement } from "Feature/Dialog";
import { GameCodeElement } from "Feature/Gamecode/GameCodeElement";
import { LogStore } from "Feature/Logging/LogStore";
import { VisitorStore } from "Feature/Visitor";

class SpotTheDifferencesElement extends HTMLElement { 
    static tag = "spot-the-differences";
    private _section: HTMLElement;
    private _button: HTMLButtonElement;

    private onResize = () => { 
        console.log("resize", window.innerWidth);
        
        this.toggleAttribute("portrait", window.innerHeight > window.innerWidth);
        this.style.setProperty("--width", `${window.innerWidth}px`);
        this.style.setProperty("--height", `${window.innerHeight}px`);

        const style = window.getComputedStyle(this);
        const headerHeight = parseInt(style.getPropertyValue("--header-height"));
        const height = window.innerHeight - headerHeight;

        let shortest;
        if (window.innerHeight > window.innerWidth) {
            shortest = window.innerWidth  - 10;
        } else { 
            shortest = Math.min(window.innerWidth / 2 - 10, window.innerHeight);
        }
        this.style.setProperty("--shortest", `${shortest}px`);
    };
    
    async connectedCallback() {
        this.innerHTML = this.view();

        const answer = this.getAttribute("answer-image");
        await SpotStore.instance.initializeAnswers(this.getAttribute("puzzle-id"), answer, parseInt(this.getAttribute("spots")));

        this.removeAttribute("answer-image");
        this.removeAttribute("control-image");
        this.removeAttribute("puzzle-image");

        this.onResize();
        window.addEventListener("resize", this.onResize);
        this._button = this.querySelector("button[name=clear]");
        this._button.addEventListener("click", this.onClear);

        SpotStore.instance.addEventListener("puzzle-complete", this.onPuzzleComplete);

    }

    disconnectedCallback() { 
        window.removeEventListener("resize", this.onResize);
        this._button?.removeEventListener("click", this.onClear);
        SpotStore.instance.removeEventListener("puzzle-complete", this.onPuzzleComplete);
    }

    private onPuzzleComplete = async () => { 

        

        LogStore.instance.logInformation("Solved spot the differences", {
            page: window.location.pathname,
            title: document.title,
            referrer: document.referrer,
            eventKind: "page-hit",
            visitorId: VisitorStore.instance.visitorId
        });


        await UserPromptElement.show("Congratulations!", "Well done, you found all the differences!", [{ id: "ok", title: "Yay", intent: "primary" }]);

        SpotStore.instance.clear(this.getAttribute("puzzle-id"));
        
        this.showSuccess();

        
    }

    private showSuccess = () => {
        document.body.setAttribute("name", "campaign");

        var gameCodeElement = new GameCodeElement();
        gameCodeElement.setAttribute("campaign-id", this.getAttribute("puzzle-id"));
        this.replaceWith(gameCodeElement);

        window.scrollTo(0, 0);
    }


    private onClear = () => {
        SpotStore.instance.clear(this.getAttribute("puzzle-id"));
    }

    private view = () => `
        
        <header>
            <h1>${this.getAttribute("puzzle-title")}</h1>
            <p>Can you spot the 5 differences between the two pictures?</p>
            <p>Mark the differences on the images, green is right, red is wrong. Tap again to remove a mark.</p>

           
        </header>

        <section>
            <button type="button" name=clear title="Start over">
                ${require("./Images/start-over.svg")}
            </button>

            <${SpotFinderElement.tag} source="${this.getAttribute("control-image")}" puzzle-id="${this.getAttribute("puzzle-id")}"></${SpotFinderElement.tag}>
            <${SpotFinderElement.tag} source="${this.getAttribute("puzzle-image")}" puzzle-id="${this.getAttribute("puzzle-id")}"></${SpotFinderElement.tag}> 
        </section>
    `;
}

customElements.define(SpotTheDifferencesElement.tag, SpotTheDifferencesElement);