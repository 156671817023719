import EventTarget from "@ungap/event-target";


interface EventMap {
    "spot-added": Spot;
    "spot-removed": Spot;
    "puzzle-active": boolean;
    "puzzle-complete": undefined;
}

interface Answer {
    context: CanvasRenderingContext2D;
    image: HTMLImageElement;
}

export class SpotStore {


    private _eventTarget = new EventTarget();
    private _spots: Spot[] = undefined;
    private _answer: Answer = undefined;
    private _spotCount: number = undefined;

    private static _instance = new SpotStore();
    public static get instance() { return SpotStore._instance; }

    public async initializeAnswers(puzzleId: string, answerImage: string, spotCount: number) {
        this._spotCount = spotCount;
        this.ensureInitioalized(puzzleId);

        const image = await this.loadImage(answerImage);

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d", { willReadFrequently: true });

        canvas.height = image.height;
        canvas.width = image.width;

        context.drawImage(image, 0, 0);

        this._answer = { context, image };

        console.log("initialized answer");

        this.emit("puzzle-active", this._spots.length < this._spotCount);
    }

    private loadImage(imageBase64: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = `data:image/png;base64,${imageBase64}`;
        });
    }



    private ensureInitioalized(puzzleId: string) {
        if (!puzzleId) {
            throw new Error("puzzleId is required");
        }

        if (!this._spots) {
            try {
                this._spots = JSON.parse(localStorage.getItem(`puzzle-${puzzleId}`) || "[]");
            }
            catch (error) {
                console.log("error parsing", error);
                this._spots = [];
            }
        }
    }

    private convertPercentToPixel(percent: number, maxPixel: number): number {
        return (percent / 100) * maxPixel;
    }

    private persist = (puzzleId: string) => {
        localStorage.setItem(`puzzle-${puzzleId}`, JSON.stringify(this._spots));
    }

    private getAnswerId(spot: Spot) {
        const x = this.convertPercentToPixel(spot.left, this._answer.image.width);
        const y = this.convertPercentToPixel(spot.top, this._answer.image.height);

        const pixel = this._answer.context.getImageData(x, y, 1, 1).data;
        const isTransparent = pixel[0] === 0 && pixel[1] === 0 && pixel[2] === 0 && pixel[3] <= 1;

        if (isTransparent)
            return undefined;

        return this.colorToHex(pixel);

    }

    private colorToHex(pixels: Uint8ClampedArray) {
        const r = pixels[0];
        const g = pixels[1];
        const b = pixels[2];
        if (r > 255 || g > 255 || b > 255)
            throw "Invalid color component";
        const str = ((r << 16) | (g << 8) | b).toString(16);
        return `#${(`000000${str}`).slice(-6)}`;
    }

    clear = (puzzleId: string) => {
        for (const spot of this._spots) {
            this.remove(puzzleId, spot.id);
        }
    }


    add(puzzleId: string, spot: Spot) {
        this.ensureInitioalized(puzzleId);

        // assign random id to spot
        spot.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);


        // assume incorrect
        spot.correct = false;

        // check if percent is wihin canvas
        spot.answerId = this.getAnswerId(spot);

        if (spot.answerId) {
            if (!this._spots.some(s => s.answerId === spot.answerId)) {
                spot.correct = true;
            }
        }

        this._spots.push(spot);
        this.emit("spot-added", spot);

        this.emit("puzzle-active", this._spots.length < this._spotCount);

        console.log("added spot", spot, this._spots.length, this._spotCount);

        if (this._spots.filter(m => m.correct).length === this._spotCount) {
            this.emit("puzzle-active", false);
            this.emit("puzzle-complete", undefined);
        }

        this.persist(puzzleId);
    }

    remove(puzzleId: string, spotId: string) {
        this.ensureInitioalized(puzzleId);

        const toBeRemoved = this._spots.find(spot => spot.id === spotId);
        this._spots = this._spots.filter(spot => spot.id !== spotId);

        if (toBeRemoved) {
            this.emit("spot-removed", toBeRemoved);
        }

        this.emit("puzzle-active", this._spots.length < this._spotCount);

        this.persist(puzzleId);
    }

    getSpots(puzzleId: string) {
        this.ensureInitioalized(puzzleId);
        return this._spots;
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this._eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this._eventTarget.dispatchEvent(new CustomEvent(type, { detail: value }));
    }
}

export interface Spot {
    id?: string;
    left: number;
    top: number;
    correct?: boolean;
    answerId?: string;
}